<template>
  <div class="pre-storage-container" @click="offSelect">
    <CSTabBar
        :checked-tab="checkedTaBar"
        :tabbar="tabBar"
        @changeTabBar="changeTabBar"
    ></CSTabBar>
    <!-- 按钮 -->
    <div class="filter-panel">
      <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
        <el-date-picker
            v-model="conditions.startTime"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            prefix-icon="el-icon-time"
            style="width: 214px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"> </span>
      <CSSelect i-width="36rpx" style="width: 230px">
        <el-date-picker
            v-model="conditions.endTime"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            prefix-icon="el-icon-time"
            style="width: 214px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <div
          style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
      >
        <input
            v-model="conditions.selectPut"
            :placeholder="inputTitle"
            style="
            color: #000;
            width: 240px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 20px;
          "
            type="text"
        />
      </div>
      <CSSelect>
        <select v-model="conditions.state">
          <option value="">发票状态不限</option>
          <option
              v-for="identity in invoiceStatus"
              :key="identity.id"
              :value="identity.id"
          >
            {{ identity.name }}
          </option>
        </select>
      </CSSelect>
      <button class="btn btn-primary btn-sm" type="button" @click="query()">
        查询
      </button>
    </div>
    <!-- 表单 -->
    <div class="result-panel">
      <CSTable v-if="checkedTaBar == 1" :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <div class="moneyClass" style="margin-top: 5px">金额总计:{{ amount ? amount : 0 }}元</div>
            <button class="btn btn-primary sticky-right" @click="wateRecharge">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              线下充值
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>充值时间</th>
            <th>水表编号</th>
            <th>读数</th>
            <th>关联房间</th>
            <th>业主/租客</th>
            <th>充值人</th>
            <th>充值金额</th>
            <th>预存后剩余金额</th>
            <th>支付渠道</th>
            <th>发票状态</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="PreStorageList.length > 0">
            <tr v-for="item in PreStorageList" :key="item.id">
              <td class="date-td">{{ item.createDate }}</td>
              <td>{{ item.meterNo }}</td>
              <td>{{ item.ton }}</td>
              <td>{{ item.correlationRoom }}</td>
              <td>
                  <span class="btnActive" @click="tenantInformation(item)">{{
                      item.companyName
                    }}</span>
              </td>
              <td>
                {{ item.userName || '-' }}
              </td>
              <td>{{ item.amount }}元</td>
              <td>
                {{ item.residuePrice }}
              </td>
              <td>
                  <span class="btnActive" @click="btnRechargeChannel(item)">{{
                      item.paymentMethod == 0 ? "线下支付" : "线上支付"
                    }}</span>
              </td>
              <td>{{ INVOICE_STATUS_NAME[item.invoiceState] }}</td>
              <td>
                <div>
                  <template v-if="item.invoiceState === 0">
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="btnDrawBill(item)"
                    >
                      开发票
                    </button>
                  </template>
                  <template v-else>
                    <button
                        :disabled="true"
                        class="btn"
                        style="background:#cccc;color:#ffff"
                        type="button"
                    >
                      开发票
                    </button>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>

      <CSTable v-else :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <div class="moneyClass" style="margin-top: 5px">金额总计: {{ electricAmount }}元</div>
            <button class="btn btn-primary sticky-right" @click="wateRecharge">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              线下充值
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>充值时间</th>
            <th>电表编号</th>
            <th>读数</th>
            <th>关联房间</th>
            <th>业主/租客</th>
            <th>充值人</th>
            <th>充值金额</th>
            <th>预存后剩余金额</th>
            <th>支付渠道</th>
            <th>发票状态</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template>
            <tr v-for="item in meterPreStorageRrecord" :key="item.id">
              <td class="date-td">{{ item.createDate }}</td>
              <td>{{ item.meterNo }}</td>
              <td>{{ item.ton }}</td>
              <td>{{ item.correlationRoom }}</td>
              <td>
                            <span class="btnActive" @click="tenantInformation(item)">
                                {{ item.companyName }}
                            </span>
              </td>
              <td>
                {{ item.userName || '-' }}
              </td>
              <td>{{ item.amount }}元</td>
              <td>
                {{ item.residuePrice }}
              </td>
              <td>
                                <span class="btnActive" @click="btnRechargeChannel(item)">
                                    {{ item.paymentMethod == 0 ? "线下支付" : "线上支付" }}
                                </span>
              </td>
              <td>
                {{ INVOICE_STATUS_NAME[item.invoiceState] }}
              </td>
              <td>
                <div>
                  <template v-if="item.invoiceState === 0">
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="btnDrawBill(item)"
                    >
                      开发票
                    </button>
                  </template>
                  <template v-else> -</template>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination></Pagination>
    </div>

    <!-- 线下充值 -->
    <RechargeOffline
        :nameSurface="this.checkedTaBar == 1 ? '水表' : '电表'"
        componentName="Recharge"
        name="recharge"
    ></RechargeOffline>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import Pagination from "@/components/Pagination.vue";
import CSSelect from "@/components/common/CSSelect";
import RechargeOffline from "@/components/RechargeOffline.vue";
import {hydropowerContractInformationUrl, hydropowerInvoiceUrl, hydropowerPreStorageUrl,} from "@/requestUrl";
import {HYDROPOWER, INVOICE_STATUS_NAME} from "@/constant";
import CSTable from "@/components/common/CSTable";
import dayjs from "dayjs";

export default {
  created() {
    this.query();
    this.$vc.on("recharge", "queryNew", () => {
      this.query();
    });
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (res) => {
      this.pageNo = res;
      this.query(res);
    });
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  data() {
    return {
      amount: 0,
      electricAmount: 0,
      filterHeight: 0,
      INVOICE_STATUS_NAME, //发票状态
      PAY_CHANNEL_MAPPING: {
        3: "微信",
        2: "支付宝",
        0: "现金支付",
        1: "银行转账",
      }, //支付渠道
      inputTitle: "搜索水表编号/关联房间/业主/租客",
      //发票状态
      invoiceStatus: [
        {id: 1, name: "已开"},
        {id: 0, name: "未开"},
      ],
      checkedTaBar: 1,
      tabBar: {
        1: "水表",
        2: "电表",
      },
      //表单数据
      conditions: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        type: 0,
        selectPut: "",
        state: "",
      },
      PreStorageList: [],
      meterPreStorageRrecord: [],
      pageNo: 1,
    };
  },
  components: {
    CSTable,
    CSTabBar,
    Pagination,
    CSSelect,
    RechargeOffline,
  },
  activated() {
    window.addEventListener("keydown", this.queryEnd);
  },
  deactivated() {
    window.removeEventListener("keydown", this.queryEnd);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryEnd);
  },
  methods: {
    //关闭选择框
    offSelect(e) {
      this.$vc.emit("rechargeOffline", false);
    },
    //开发票
    btnDrawBill(item) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定发票已开？`,
        onConfirm: () => {
          this.$fly
              .get(hydropowerInvoiceUrl, {
                id: item.id,
              })
              .then((res) => {
                if (res.code != 0) {
                  return;
                }
                this.$vc.toast("发票已开");
                this.$CSDialog.instance.closeDialog();
                this.query();
              });
        },
      });
    },
    //充值渠道信息查询-------------------
    btnRechargeChannel(item) {
      if (item.paymentMethod == 1) {
        this.$CSDialog.alert({
          width: "750px",
          title: "支付渠道",
          messageHtml: `
                    <div class="comment-panel" style="--item-label: 96px;">
                        <div class="item right">
                            <div class="item-label" style="width: 145px;">支付渠道</div>
                            <div>${
              this.PAY_CHANNEL_MAPPING[item.payChannel]
          }</div>
                        </div>
                        <div class="item right">
                            <div class="item-label" style="width: 145px;">支付单号</div>
                            <div>${item.payOrderNo}</div>
                        </div>
                    </div>
                `,
        });
      } else {
        //console.log(item.payOrder);
        // var scrListItem = item.payOrder ? item.payOrder:'[]';
        //var srcList = JSON.parse(scrListItem);
        var srcList = item.payOrder;
        var srcHtml = "";
        switch (srcList.length) {
          case 0:
            srcHtml = `
                    <div class="comment-panel" style="--item-label: 96px;">
                        <div class="item right">
                            <div class="item-label" style="width: 145px;">支付渠道</div>
                            <div>${
                this.PAY_CHANNEL_MAPPING[item.payChannel]
            }</div>
                        </div>
                        <div class="item right" style = "display: flex;">
                            <div class="item-label" style="width: 145px;">支付凭证</div>
                            <div style = "display: flex;">
                            </div>
                        </div>
                    </div>
                `;
            break;
          case 1:
            srcHtml = `
                    <div class="comment-panel" style="--item-label: 96px;">
                        <div class="item right">
                            <div class="item-label" style="width: 145px;">支付渠道</div>
                            <div>${
                this.PAY_CHANNEL_MAPPING[item.payChannel]
            }</div>
                        </div>
                        <div class="item right" style = "display: flex;">
                            <div class="item-label" style="width: 145px; height: 24px; line-height: 24px;">支付凭证</div>
                            <div style = "display: flex;" v-if="scrList.length > 0">
                                <div style="width: 100px;height:100px;background: #DCDCDC;border-radius: 5px; margin-right:20px" class = "enlarge__imga" >
                                  <img src=${
                srcList[0]
            } style="width:100%;height:100%">
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            break;
          case 2:
            srcHtml = `
                    <div class="comment-panel" style="--item-label: 96px;">
                        <div class="item right">
                            <div class="item-label" style="width: 145px;">支付渠道</div>
                            <div>${
                this.PAY_CHANNEL_MAPPING[item.payChannel]
            }</div>
                        </div>
                        <div class="item right" style = "display: flex;">
                            <div class="item-label" style="width: 145px; height: 24px; line-height: 24px;">支付凭证</div>
                            <div style = "display: flex;" v-if="scrList.length > 0">
                                <div style="width: 100px;height:100px;background: #DCDCDC;border-radius: 5px; margin-right:20px"  >
                                  <img class = "enlarge__imga" src=${
                srcList[0]
            } style="width:100%;height:100%">
                                </div>
                                <div style="width: 100px;height:100px;background: #DCDCDC;border-radius: 5px; margin-right:20px" >
                                  <img class = "enlarge__imga" src=${
                srcList[srcList.length - 1]
            } style="width:100%;height:100%">
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            break;
        }
        this.$CSDialog.alert({
          width: "500px",
          title: "支付渠道",
          messageHtml: srcHtml,
        });
        var that = this;
        let enlargeArr = document.getElementsByClassName("enlarge__imga");
        enlargeArr[0]?.addEventListener("click", function () {
          that.seeVoucher(srcList[0]);
        });
        enlargeArr[1]?.addEventListener("click", function () {
          that.seeVoucher(srcList[1]);
        });
      }
    },
    //租客信息
    async tenantInformation(item) {
      var listData = [];
      console.log(item);
      try {
        listData = await this.$fly.post(hydropowerContractInformationUrl, {
          cid: item.cid,
          lesseeId: item.lesseeId
        });
      } catch {
        return;
      }

      this.$CSDialog.alert({
        width: "700px",
        title: "业主/租客",
        messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${listData.data.companyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${listData.data.startDate ? listData.data.startDate : "-"}至${listData.data.endDate ? listData.data.endDate : "-"}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${listData.data.deputyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人手机号</div>
                            <div>${listData.data.deputyPhone}</div>
                        </div>
                    </div>
                `,
      });
    },
    //切换水电表
    changeTabBar(index) {
      if (this.checkedTaBar == +index) {
        return;
      }
      this.fromInitialization();
      this.checkedTaBar = +index;
      this.inputTitle =
          this.checkedTaBar == 1
              ? "搜索水表编号/关联房间/业主/租客"
              : "搜索电表编号/关联房间/业主/租客";
      this.pageNo = 1;
      if (this.checkedTaBar == 1) {
        this.query();
      } else {
        this.query();
      }
    },
    //表单初始化
    fromInitialization() {
      this.conditions.startTime =
          dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00";
      this.conditions.endTime = dayjs().format("YYYY-MM-DD") + " 23:59";
      this.conditions.state = "";
      this.conditions.selectPut = "";
    },
    queryEnd(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
    //查询按钮
    query(pageNo = this.pageNo, pageSize = 10) {
      if (this.checkedTaBar == 1) {
        this.$fly
            .post(hydropowerPreStorageUrl, {
              regionCode: this.$vc.getCurrentRegion().code, //区域编码
              type: HYDROPOWER.WATER_METER,
              startDate: this.conditions.startTime, //起始时间
              endDate: this.conditions.endTime, //结束时间
              state: this.conditions.state,
              search: this.conditions.selectPut,
              pageNo: pageNo,
              pageSize: pageSize,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              if (pageNo == 1) {
                this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total || res.data.length,
                  pageSize: pageSize,
                  currentPage: pageNo,
                });
              }
              this.amount = res.data.amount;
              this.PreStorageList = res.data.data;
            });
      } else if (this.checkedTaBar == 2) {
        this.$fly
            .post(hydropowerPreStorageUrl, {
              regionCode: this.$vc.getCurrentRegion().code, //区域编码
              type: HYDROPOWER.WATT_HOUR_METER,
              startDate: this.conditions.startTime, //起始时间
              endDate: this.conditions.endTime, //结束时间
              state: this.conditions.state,
              search: this.conditions.selectPut,
              pageNo: pageNo,
              pageSize: pageSize,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              if (pageNo == 1) {
                this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total || res.data.length,
                  pageSize: pageSize,
                  currentPage: pageNo,
                });
              }
              this.electricAmount = res.data.amount;
              this.meterPreStorageRrecord = res.data.data;
            });
      }
    },
    //水费充值按钮
    wateRecharge() {
      this.$vc.emit("recharge", "openRecharge", "a");
      this.$vc.emit("recharge", "getTenantAllList", {});
    },
    //查看凭证
    seeVoucher(item) {
      this.$CSDialog.alert({
        width: '500px',
        title: "付款凭证",
        messageHtml: `
                    <div style="width: 400px;height:400px;background: #DCDCDC;border-radius: 5px;">
                      <img  style="width:400px;height:400px" src =${item}>
                    </div>
                `,
      });
    },
  },
};
</script>

<style scoped>
.moneyClass {
  float: left;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

span.btnActive {
  width: 196px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: #1dafff;
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
}
</style>
